var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.localeSwitchWarning = {
    // warn if user tries to switch locale and cart is not empty
    attach: function (context) {
      var prefLanguage, prefCountry, cartState;
      var locale = generic.cookie('LOCALE') || 'en_AT';

      if (locale !== '' && locale !== null) {
        prefLanguage = locale.split('_')[0];
      }

      $('body').on('click', '#cancelChangeLocaleButton, #localeSwitchWarning .close-button', function () {
        $('#localeSwitchOverlay').hide();
      });

      $('body').on('click', '#changeLocaleButton', function () {
        site.changeLocale(prefCountry, prefLanguage);
      });

      $('#countryPickerWrap .country-list a', context).off('click');
      $('#countryPickerWrap .country-list a', context).each(function () {
        $(this).on('click', function () {
          // user may be switching country. is their current language going to be supported when they land or should we choose the target country's default?
          cartState = Drupal.behaviors.gnavCart.getState() || 'empty';
          prefLanguage = site.getAltLanguage(prefLanguage, $(this).data('languages').split(','));

          requestChangeLocale($(this).data('country'), prefLanguage, $(this).data('countryname'), cartState);
        });
      });

      $('.mobileCountryLink', context).off('click');
      $('.mobileCountryLink', context).on('click', function () {
        cartState = Drupal.behaviors.gnavCart.getState() || 'empty';
        requestChangeLocaleMobile($(this).data('countryname'), cartState);
      });

      $(window).resize(function () {
        site.centerModal($('#localeSwitchWarning', context));
      });

      function requestChangeLocaleMobile(countryName, cartState) {
        if (cartState === 'nonempty') {
          // if cart has items, show the warning
          $('#localeSwitchWarning', context).html(
            $('#localeSwitchWarning', context)
              .html()
              .replace('{{{ country_name }}}', '<span id="#destinationCountryname">' + countryName + '</span>')
          );
          $('#destinationCountryname', context).html(countryName);

          $('body').append($('#localeSwitchOverlay', context).css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning', context));

          $('body').off('click', '#changeLocaleButton');
          $('body').on('click', '#changeLocaleButton', function () {
            $('body').trigger('displayLocaleInterstitial');
          });
        } else {
          // if not, show the interstitial
          $('body').trigger('displayLocaleInterstitial');
        }
      }

      function requestChangeLocale(prefC, prefL, countryName, cartState) {
        prefCountry = prefC;
        prefLanguage = prefL;

        if (cartState === 'nonempty') {
          // if cart has items, show the warning
          $('#localeSwitchWarning', context).html(
            $('#localeSwitchWarning', context)
              .html()
              .replace('{{{ country_name }}}', '<span id="#destinationCountryname">' + countryName + '</span>')
          );
          $('#destinationCountryname', context).html(countryName);

          $('body').append($('#localeSwitchOverlay', context).css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning', context));
        } else {
          // if not, change straight away
          site.changeLocale(prefCountry, prefLanguage);
        }
      }
    }
  };
})(jQuery);
